export const constant = {
    //---------- Local Server
   /*  baseUrl: 'http://127.0.0.1:5001/',
    officetypeId:'6719fe1c67ac50352ac3f166',
    companyTypeId:'6719fde367ac50352ac3f154',
    industryId:'6719fe1467ac50352ac3f163', 
    leadsourseId:'673c5d12aec1e96da5f01f97',
    projectTypeId:'673c6a552d51c1420a02c8fc',
    actionRequiredId:'673c6b212d51c1420a02ca00', */
    
    //---------- Live Server
    //baseUrl: 'http://127.0.0.1:5001/',
    baseUrl: 'https://api.leadsbuzzer.com/',
    officetypeId:'671a135c078c1871c9ca5310',
    companyTypeId:'671a1331078c1871c9ca5304',
    industryId:'671a1349078c1871c9ca530a', 
    leadsourseId:'673caee5459899d66ac9c8b9',
    projectTypeId:'673caf06459899d66ac9c911',
    actionRequiredId:'673caf72459899d66ac9ca12', 
    leadStatusId:'671a1353078c1871c9ca530d',
     
} 
   
 